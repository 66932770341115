import * as mediasoupClient from 'mediasoup-client';
const serverUrl = 'wss://audio.redenes.org';
// const serverUrl = 'ws://localhost:3000';
const ws = new WebSocket(serverUrl);
const app_env = 'dev';
let device;
let producerCallback;
let disableClick=false;
window.transports = {};
let roomsData;

// new code
// Make a GET request to the server
axios.get('/api/stats')
.then(function (response) {
  // Handle successful response
  // console.log('Data:', response.data)
  roomsData = response.data;
//   console.log(roomsData);
})
.catch(function (error) {
  // Handle error
  console.error('Error:', error);
});

ws.onopen = () => {
    console.log('Connected to the signaling server');
    ws.send(JSON.stringify({
        action: 'connect',
        authorization_id: Math.floor(Math.random()*100000000),
        full_name:"Will Richards",
        agency_name:"REDENES"
    }))
};

ws.onmessage = async (message) => {
    const msg = JSON.parse(message.data);
    if(msg.id && msg.status == 'connected') {
        document.getElementById('producerId').innerHTML = msg.id;
        ws.id = msg.id;
    }
    switch (msg.action) {
        case 'notAvailable':
            alert("Room Not Available");
            break;
        case 'limitReached':
            alert("Room Limit Reached. Too Many Speakers");
            break;
        case 'alreadyConnected':
            alert("Already Connected to that room");
            break;
        case 'alreadyPatchedConnected':
            alert("That room is already patched to your current room, Can't connect");
            break;
        case 'listMembers':
            await listMembers(msg.members.length, msg.roomId);
            break;
        case 'routerRtpCapabilities':
            await initializeDevice(msg.routerRtpCapabilities, msg.roomId, msg.patched);
            break;
        case 'transportCreated':
            await createTransport(msg.transportParams, msg.direction, msg.roomId, msg.patched);
            break;
        case 'producerCreated':
            if(producerCallback) { producerCallback(msg.producerId); }
            document.getElementById("talk-"+msg.roomId).disabled = false;
            if(msg.patched && msg.patched.length) {
                await patchAudio(msg.patched, msg.roomId);
            }
            break;
        case 'producerClosed':
            (msg.patched || []).forEach(async (roomId) => {
                if(transports[roomId].sender) {
                    await transports[roomId].sender.close();
                    if(speaking == roomId) { speaking = null; }
                    delete transports[roomId];
                    ws.send(JSON.stringify({ action: 'leaveRoom', roomId }));
                }
            });
            if(document.getElementById("talk-"+msg.roomId)) {
                document.getElementById("talk-"+msg.roomId).disabled = false;
            }
            break;
        case 'unpatched':
            (msg.rooms || []).forEach(async (roomId) => {
                console.log(msg);
                if(transports[roomId].sender && !transports[roomId].receiver) {
                    console.log('condition true, closing transport', roomId);
                    console.log(transports[roomId].sender.connectionState);
                    await transports[roomId].sender.close();
                    if(speaking == roomId) { speaking = null; }
                    delete transports[roomId];
                    ws.send(JSON.stringify({ action: 'leaveRoom', roomId }));
                }
            });
            if(document.getElementById("talk-"+msg.roomId)) {
                document.getElementById("talk-"+msg.roomId).disabled = false;
            }
            break;
        case 'consumerCreated':
            await consume(msg.consumerParams, msg.producerId, msg.roomId);
            break;
        case 'producerJoined':
            await consumeProducer(msg.producerId, msg.roomId, msg.stream, msg.producerName, msg.agencyName);
            break;
        case 'clientClosed':
            await closeClient(msg.producerId, msg.roomId);
            break;
        case 'roomClosed':
            await closeRoom(msg.roomId);
            break;
        case 'patched':
            if(transports[msg.from] && transports[msg.from].sender && transports[msg.from].sender.connectionState == 'connected') {
                for (let i = 0; i < msg.rooms.length; i++) {
                    const room = msg.rooms[i];
                    if(transports[room] && transports[room].receiver) {
                        document.getElementById("room_box_"+room).getElementsByClassName("connect_btn connected")[0].click();
                    }
                }
                await patchAudio(msg.rooms, msg.from);
            }
            break;
    }
};

window.joinRoom = async function (event, roomId, patched) {
    if(!roomId) { return; }
    if(transports[roomId]) { alert("already connected to this room"); return; }
    if(!transports[roomId]) {
        ws.send(JSON.stringify({ action: 'getRouterRtpCapabilities', roomId: roomId, patched }));
    }
}

window.speaking = null;

async function listMembers(count, roomId) {
    var div = document.getElementById("room_box_"+roomId);
    div.getElementsByClassName('btn4')[0].innerHTML = count;
    if(app_env == 'prod'){
        setTimeout(() => {
            ws.send(JSON.stringify({ action: 'getMembers', roomId: roomId }));
        }, 2000);
    }
}

async function consumeProducer(producerId, roomId, stream, name, agency) {
    if(stream) {
        await consumeStream(producerId, roomId);
    } else {
        const rtpCapabilities = device.rtpCapabilities;
        ws.send(JSON.stringify({ action: 'createConsumer', roomId, producerId, rtpCapabilities }));
    }
    var div = document.getElementById("room_box_"+roomId);
    div.getElementsByClassName('speaker')[0].innerHTML = name+'<br/>'+agency;
    div.getElementsByClassName('speaker')[0].style.background = "radial-gradient(#fff, #54b755)";
};

let fired = false;
document.body.addEventListener('keydown', async function (e) {
    if(fired) { return; }
    if(e.key == '1' || e.key == '2' || e.key == '3') {
        fired = true;
        const elem = document.querySelector('[tKey="'+e.key+'"]');
        if(elem.startSpeaking) {
            await elem.startSpeaking();
        }
    }
});
document.body.addEventListener('keyup', async function (e) {
    if(e.key == '1' || e.key == '2' || e.key == '3') {
        fired = false;
        const elem = document.querySelector('[tKey="'+e.key+'"]');
        if(elem.stopSpeaking) {
            await elem.stopSpeaking();
        }
    }
});
let elemClicked = null;
document.body.addEventListener('mousedown', async function (e) {
    elemClicked = (e.target);
    if(elemClicked.startSpeaking) {
        elemClicked.startSpeaking();
    }
});
document.body.addEventListener('mouseup', async function (e) {
    if(elemClicked.stopSpeaking) {
        elemClicked.stopSpeaking();
    }
});

async function initializeDevice(routerRtpCapabilities, roomId, patched) {
    try {
        ws.send(JSON.stringify({ action: 'getMembers', roomId: roomId }));
        device = new mediasoupClient.Device();
        await device.load({ routerRtpCapabilities });
        transports[roomId] = {};

        if(patched && transports[patched] && transports[patched].sender) {
            ws.send(JSON.stringify({ action: 'createTransportSend', roomId, patched }));
            return;
        }

        var div = document.getElementById("room_box_"+roomId);

        const talkElement = div.getElementsByClassName('transmit_btn')[0];
        const leaveElement = div.getElementsByClassName('connect_btn')[0];
        const volumeElement = div.getElementsByClassName('volume_btn')[0];
        talkElement.disabled = false;
        talkElement.style.backgroundColor = "#fff";
        talkElement.id = "talk-"+roomId;
        // talkElement.innerText = "Talk to Room : "+roomId;
        leaveElement.innerText = "Connected";
        leaveElement.classList.add('connected');
        leaveElement.onclick = async () => {
            if(transports[roomId].sender) {
                await transports[roomId].sender.close();
                if(speaking == roomId) {
                    speaking = null;
                }
            }
            talkElement.disabled = true;
            talkElement.style.backgroundColor = '#f5f5f5';
            leaveElement.innerText = "Connect";
            leaveElement.classList.remove('connected');
            div.getElementsByClassName('speaker')[0].innerHTML = '';
            div.getElementsByClassName('speaker')[0].style.background = "radial-gradient(#fff, #c4c4c4)";
            delete transports[roomId];
            // Array.from(document.getElementsByClassName("audio-"+roomId)).forEach(el => {
            //     el.remove();
            // });
            // p.remove();
            ws.send(JSON.stringify({ action: 'leaveRoom', roomId }));
            leaveElement.onclick = async (e) => {
                return joinRoom(e, roomId)
            };
        }
        talkElement.startSpeaking = async () => {
            if(talkElement.disabled) { return; }
            console.log("startSpeaking");
            if(!speaking) {
                if(transports[roomId] && !transports[roomId]['sender']) {
                    speaking = roomId;
                    ws.send(JSON.stringify({ action: 'createTransportSend', roomId }));
                    talkElement.style.backgroundColor = "yellow";
                }
            } else if (speaking && speaking != roomId) {
                alert("You are already speaking to room : "+speaking);
            }
        }
        talkElement.stopSpeaking = async () => {
            if(talkElement.disabled) { return; }
            console.log("stopSpeaking", speaking);
            if(speaking && speaking == roomId) {
                speaking = null;
                try {
                    await transports[roomId].sender.close();
                    talkElement.style.backgroundColor = "#fff";
                } catch($e) {
                    var sI = setInterval(async () => {
                        console.log('interval running');
                        if((transports[roomId].sender && transports[roomId].sender.connectionState == 'connected') || speaking == null) {
                            if(transports[roomId].sender) {
                                await transports[roomId].sender.close();
                            }
                            talkElement.style.backgroundColor = "#fff";
                            clearInterval(sI);
                        }
                    }, 500);
                }
            }
        }
        ws.send(JSON.stringify({ action: 'createTransportReceive', roomId }));
    } catch (error) {
        console.error('Failed to load device', error);
    }
}

async function patchAudio(rooms, roomId) {
    rooms.forEach(room => {
        joinRoom(null, room, roomId);
    });
}

async function createTransport(transportParams, direction, roomId, patched) {
    try {
        if (direction == 'send') { 
            transports[roomId]['sender'] = await device.createSendTransport(transportParams);
            await setupTransportListeners(transports[roomId]['sender'], direction, roomId);
            if(patched && transports[patched] && transports[patched].sender) {
                // await sendAudio(window.stream, roomId);
                const stream = await getLocalAudioStream();
                await sendAudio(stream, roomId);
            } else {
                const stream = await getLocalAudioStream();
                await sendAudio(stream, roomId);
            }
        } else if (direction == 'receive') {
            transports[roomId]['receiver'] = await device.createRecvTransport(transportParams);
            await setupTransportListeners(transports[roomId]['receiver'], direction, roomId);
        }
    } catch (error) {
        console.error('Failed to create send transport', error);
    }
}

async function consume(consumerParams, producerId, roomId) {
    try {
        var box = document.getElementById("room_box_"+roomId);
        const volumeElement = box.getElementsByClassName('volume_btn')[0];
        const consumer = await transports[roomId]['receiver'].consume(consumerParams);
        const div = document.createElement('div');
        div.innerHTML = 'Room: '+roomId+' Producer: '+producerId;
        const audioElement = document.createElement('audio');
        audioElement.id = "audio-"+roomId+'-'+producerId;
        audioElement.className = "audio-"+roomId;
        audioElement.srcObject = new MediaStream([consumer.track]);
        audioElement.controls = true;
        audioElement.volume = volumeElement.value/100;
        volumeElement.onchange = function (e) {
            audioElement.volume = e.target.value/100;
        }
        div.appendChild(audioElement);
        document.body.append(div);
        audioElement.play();
    } catch (error) {
        console.error('Error in consume', error);
    }
}

async function consumeStream(producerId, roomId) {
    try {
        var box = document.getElementById("room_box_"+roomId);
        const volumeElement = box.getElementsByClassName('volume_btn')[0];
        const div = document.createElement('div');
        div.innerHTML = 'Room: '+roomId+' Producer: '+producerId;
        const audioElement = document.createElement('audio');
        audioElement.id = "audio-"+roomId+'-'+producerId;
        audioElement.className = "audio-"+roomId;
        // audioElement.srcObject = new MediaStream([consumer.track]);
        audioElement.controls = true;
        div.appendChild(audioElement);
        document.body.append(div);
        audioElement.volume = volumeElement.value/100;
        volumeElement.onchange = function (e) {
            audioElement.volume = e.target.value/100;
        }
        audioElement.onplay = () => {
            console.log(audioElement.currentTime, audioElement.duration);
            audioElement.currentTime = audioElement.duration;
        }
        if (Hls.isSupported()) {
            var hls = new Hls({
                autoStartLoad: true,
                startPosition: -2 // Start from the end of the live window
            });
            setTimeout(() => {
                hls.loadSource('/files/'+roomId+'.m3u8');
                hls.attachMedia(audioElement);
                hls.on(Hls.Events.MANIFEST_PARSED, function() {
                    audioElement.play();
                });
            }, 2000);
        }
        else if (audioElement.canPlayType('application/vnd.apple.mpegurl')) {
            setTimeout(() => {
                audioElement.src = '/files/'+roomId+'.m3u8';
                audioElement.play();
            }, 2000);
        }

    } catch (error) {
        console.error('Error in consume', error);
    }
}

async function sendAudio(stream, roomId) {
    try {
        const track = stream.getAudioTracks()[0];
        if(!transports[roomId]['sender'].closed) {
            const producer = await transports[roomId]['sender'].produce({ track });
            producer.on('trackended', () => console.log('Audio track ended'));
            producer.on('transportclose', () => {
                console.log('Transport for this producer closed')
                ws.send(JSON.stringify({ action: 'closeProducer', roomId, producerId: ws.id}));
                delete transports[roomId]['sender'];
            });
        } else {
            await transports[roomId]['sender'].close();
            console.log('Transport for this producer closed')
            ws.send(JSON.stringify({ action: 'closeProducer', roomId, producerId: ws.id}));
            delete transports[roomId]['sender'];
        }
    } catch (error) {
        console.error('Error in sendAudio', error);
        await transports[roomId]['sender'].close();
        console.log('Transport for this producer closed')
        ws.send(JSON.stringify({ action: 'closeProducer', roomId, producerId: ws.id}));
        delete transports[roomId]['sender'];
    }
}

async function setupTransportListeners(transport, direction, roomId) {
    transport.on('connect', ({ dtlsParameters }, callback, errback) => {
        ws.send(JSON.stringify({ action: 'connectTransport', dtlsParameters, roomId, direction }));
        callback();
    });
    transport.on('produce', ({ kind, rtpParameters }, callback, errback) => {
        ws.send(JSON.stringify({ action: 'createProducer', rtpParameters, roomId, direction }));
        producerCallback = callback;
    });
}

async function getLocalAudioStream() {
    try {
        window.stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
        return window.stream;
    } catch (error) {
        alert("no mic found");
        console.error('Error accessing media devices', error);
    }
}

async function closeClient(producerId, roomId) {
    document.getElementById("audio-"+roomId+'-'+producerId)?.remove();

    var div = document.getElementById("room_box_"+roomId);
    div.getElementsByClassName('speaker')[0].innerHTML = '';
    div.getElementsByClassName('speaker')[0].style.background = "radial-gradient(#fff, #c4c4c4)";
}
async function closeRoom(roomId) {
    transports[roomId].receiver && transports[roomId].receiver.close();
    transports[roomId].sender && transports[roomId].sender.close();
    var roomelems = document.getElementsByClassName("audio-"+roomId);
    for (let i = 0; i < roomelems.length; i++) {
        const element = roomelems[i];
        element.remove();
    }
    delete transports[roomId];
}